import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
import { color, wrapper, breakpoints, border } from '../../utils/style';
import ButtonLink from '../../components/shared/ButtonLink';
import Breadcrumbs from '../../components/shared/Breadcrumbs';

const Wrapper = styled('div')`
  ${wrapper};
  margin: 30px auto;
  .gatsby-image-wrapper {
  }
  p {
    margin-bottom: 1rem;
  }
  ul {
    list-style: none;
  }
  @media (max-width: ${breakpoints.md}) {
    padding: 30px 20px;
    li > div {
      padding: 0;
    }
  }
`;

const ItemWrap = styled('div')`
  margin-bottom: 20px;
  padding: 20px;
  border-radius: ${border.borderRadius4};
`;

const ImgWrap = styled('div')`
  display: inline-block;
  vertical-align: top;
  width: 280px;
  height: 100%;
  text-align: center;
  img {
    border-radius: ${border.borderRadius4};
  }
  @media (max-width: ${breakpoints.sm}) {
    display: block;
    padding: 0;
    margin: 0 auto 20px;
    width: auto;
  }
`;

const DescWrap = styled('div')`
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 280px);
  padding-left: 33px;
  div {
    margin-bottom: 1rem;
  }
  h3 {
    margin-top: 0;
    a {
      color: ${color.white};
    }
    a:hover,
    a:active,
    a:focus {
      color: ${color.yellow};
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    width: 100%;
    text-align: center;
    display: block;
    padding: 0;
    margin-bottom: 40px;
    h3 {
      margin: 30px 0 10px;
    }
  }
`;

// REFACTOR
// Spread / etc
const Category = ({ data, pageContext }) => {
  const currentCategory = data.allWordpressCategory.edges[0].node;
  const metaTitle =
    currentCategory.yoast_meta && currentCategory.yoast_meta.yoast_wpseo_title
      ? currentCategory.yoast_meta.yoast_wpseo_title
      : `${currentCategory.title}- ${process.env.SITE_NAME_SHORT}`;
  const metaDesc =
    currentCategory.yoast_meta && currentCategory.yoast_meta.yoast_wpseo_metadesc
      ? currentCategory.yoast_meta.yoast_wpseo_metadesc
      : '';
  const { breadcrumbs } = pageContext;
  return (
    <Layout>
      <SEO title={metaTitle} description={metaDesc} pathname={currentCategory.path} breadcrumbs={breadcrumbs} />
      {currentCategory.acf && currentCategory.acf.category_image && currentCategory.acf.category_image.localFile && (
        <div>
          <Img
            fluid={currentCategory.acf.category_image.localFile.childImageSharp.fluid}
            alt={
              currentCategory.acf.category_image.alt_text
                ? currentCategory.acf.category_image.alt_text
                : currentCategory.name
            }
          />
        </div>
      )}
      <Wrapper>
        <Breadcrumbs breadcrumbs={breadcrumbs} page_title={currentCategory.name} />
        <h1 dangerouslySetInnerHTML={{ __html: currentCategory.name }} />
        <ul>
          {data.allWordpressPost.edges?.map(({ node }) => {
            const cats_id = node.categories.reduce((ids, cat) => {
              ids.push(cat.wordpress_id);
              return ids;
            }, []);
            if (!cats_id.includes(currentCategory.wordpress_id)) return null;

            return (
              <li key={node.id}>
                <ItemWrap key={node.slug}>
                  {node?.featured_media?.localFile?.childImageSharp &&
                    node?.featured_media?.alt_text ? (
                      <ImgWrap>
                        <Img
                          fluid={node.featured_media.localFile.childImageSharp.fluid}
                          alt={node.featured_media.alt_text}
                        />
                      </ImgWrap>
                    ) : (
                      <ImgWrap>
                        <Img fixed={data?.placeholder?.childImageSharp.fixed} alt={node.title} />
                      </ImgWrap>
                    )}
                  <DescWrap>
                    <h3>
                      <Link to={`${node.slug}/`}>{node.title}</Link>
                    </h3>
                    <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                    <ButtonLink to={`${node.slug}/`} text="อ่านเพิ่มเติม" ariaText="อ่านเพิ่มเติม" />
                  </DescWrap>
                </ItemWrap>
              </li>
            );
          })}
        </ul>
        {currentCategory.description && <div dangerouslySetInnerHTML={{ __html: currentCategory.description }} />}
      </Wrapper>
    </Layout>
  );
};

export default Category;

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    allWordpressCategory(filter: { wordpress_id: { eq: $wordpress_id } }) {
      edges {
        node {
          id
          name
          slug
          description
          path
          link
          wordpress_id
          acf {
            category_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1980) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          yoast_meta {
            yoast_wpseo_title
            yoast_wpseo_metadesc
          }
        }
      }
    }
    allWordpressPost(sort: { fields: [date] }) {
      edges {
        node {
          title
          excerpt
          slug
          categories {
            wordpress_id
          }
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    placeholder: file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        fixed(width: 190, height: 190) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
